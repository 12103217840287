import React from "react";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import { Layout } from "~/templates/Layout";
import { PageHeading } from "~/atoms/PageHeading";
import { Options } from "~/molecules/BaseArticleBody";
import { Container } from "~/atoms/Container";
import { graphql } from "gatsby";
import { colors } from "~/utils/colors";
import { breakPoints } from "~/utils/variables";
import { StartupGuideForm } from "~/organisms/StartupGuideForm";
import { CompleteGuideBookForm } from "~/organisms/CompleteGuideBookForm";
import { CaseCollectionForm } from "~/organisms/CaseCollectionForm";

interface Props {
  pageContext: {
    contentfulId: string;
  };
  path: string;
  data: {
    contentfulDownload: GatsbyTypes.ContentfulDownload;
  };
}

const STARTUP_GUIDE_ID = `1yMAX5hrFdwB0GcY5Jkqks`;
const COMPLETE_GUIDE_ID = `47rpbNcHnK8lHpRaVGxXHC`;
const CASE_COLLECTION_ID = `4ZX4flYZbHrW2hEFsMm7ty`;
const DownloadForm: React.FC<Props> = ({ pageContext: { contentfulId }, data }) => {
  const { title, description, ogpDescription, documentTitle, documentCover, fileType, formspreeEndpoint, pathSlug } =
    data.contentfulDownload;
  const sharedOgpDescription = ogpDescription?.internal.content;
  const thanksPath = `/downloads/${pathSlug}/thanks`;
  const renderForm = (contentfulId: string) => {
    switch (contentfulId) {
      case STARTUP_GUIDE_ID:
        return <StartupGuideForm formspreeEndpoint={formspreeEndpoint} thanksPath={thanksPath} />;
      case COMPLETE_GUIDE_ID:
        return <CompleteGuideBookForm formspreeEndpoint={formspreeEndpoint} thanksPath={thanksPath} />;
      case CASE_COLLECTION_ID:
        return <CaseCollectionForm formspreeEndpoint={formspreeEndpoint} thanksPath={thanksPath} />;
      default:
        return null;
    }
  };

  return (
    <>
      <style jsx>{`
        .wrapper {
          background: ${colors.bgGrayGradient};
          padding: 0 0 50px;
        }
        .download {
          padding: 0 30px;
        }
        .leftWrapper {
          margin: 0 30px 0 0;
          width: calc(60% - 30px);
        }
        .rightWrapper {
          width: 40%;
        }
        .mainContent {
          padding: 15px 20px;
        }
        .mainContent :global(p) {
          font-size: 16px;
          margin: 0 0 25px;
        }
        .mainContent :global(p:last-child) {
          margin: 0;
        }
        .mainContent :global(h2) {
          border-left: solid 3px ${colors.brand};
          color: ${colors.brand};
          font-size: 21px;
          margin: 40px 0;
          padding: 0 0 0 20px;
        }
        .mainContent :global(h3) {
          border-bottom: solid 1px ${colors.defaultText};
          font-size: 18px;
          margin: 40px 0;
          padding: 0 0 10px;
        }
        .mainContent :global(h4) {
          font-size: 18px;
          font-weight: 500;
          margin: 40px 0;
        }
        .documentBox {
          align-items: center;
          background-color: ${colors.white};
          display: flex;
          margin: 20px 0 0;
        }
        .documentImg {
          background-image: url(${documentCover?.fluid?.src});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          margin: 15px;
          width: calc(40% - 30px);
          aspect-ratio: 16/9;
          border: solid 1px ${colors.littleBrightGray};
        }
        .documentText {
          margin: 15px;
          width: calc(60% - 30px);
        }
        .documentTitle {
          font-size: 17px;
          white-space: pre-wrap;
        }
        .fileType {
          color: ${colors.littleBrightGray};
          font-size: 14px;
        }
        @media (${breakPoints.sp}) {
          .download :global(.container) {
            flex-direction: column;
          }
          .leftWrapper {
            width: 100%;
            margin: 0 0 15px;
          }
          .rightWrapper {
            width: 100%;
          }
          .mainContent {
            padding: 0;
          }
          .documentBox {
            flex-direction: column;
            margin: 20px 0;
            padding: 15px;
          }
          .documentImg {
            margin: 0 0 20px;
            width: 100%;
          }
          .documentText {
            margin: 0;
            width: 100%;
          }
          .documentTitle {
            font-size: 16px;
          }
        }
      `}</style>
      <Layout
        title={title || ``}
        ogpDescription={sharedOgpDescription || ``}
        ogpImageUrl={(documentCover && `https:${documentCover?.fluid?.src}`) || ``}
      >
        <div className="wrapper">
          <PageHeading title={title || ``} />
          <div className="download">
            <Container>
              <div className="leftWrapper">
                <div className="mainContent">{renderRichText(description, Options)}</div>
                <div className="documentBox">
                  <div className="documentImg" />
                  <div className="documentText">
                    <h2 className="documentTitle">{documentTitle}</h2>
                    <span className="fileType">{fileType}</span>
                  </div>
                </div>
              </div>
              <div className="rightWrapper">{renderForm(contentfulId)}</div>
            </Container>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default DownloadForm;

export const downloadFormQuery = graphql`
  query DownloadForm($contentfulId: String) {
    contentfulDownload(contentful_id: { eq: $contentfulId }) {
      formspreeEndpoint
      pathSlug
      documentCover {
        fluid(maxWidth: 767) {
          src
          aspectRatio
        }
      }
      description {
        raw
      }
      title
      documentTitle
      fileType
      ogpDescription {
        internal {
          content
        }
      }
    }
  }
`;
